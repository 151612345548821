<template>
    <div>
        <el-container >
            <el-scrollbar >
                <div class="row" v-for="(item, index) in list" :key="index">
                    <div class="row_title">
                        {{index+1}}.{{item.title}}
                    </div>
                    <div class="row_text" v-for="(item1, index1) in item.text" :key="index1">
                        {{item1}}
                    </div>
                    <div class="row_listImg" >
                        <template v-for="(item1, index1) in item.img" :key="index1" >
                            <div class="row_img">
                                <el-tooltip content="点击放大图片" :show-after="500" v-if="item1">
                                    <el-image :src="require('@/assets/' + item1)" alt="点击放大" fit="contain"
                                              :hide-on-click-modal="true" :preview-teleported="true"
                                              :preview-src-list="[require('@/assets/' + item1)]"></el-image>
                                </el-tooltip>
                                <div class="row_tip">图{{index1+1}}</div>
                            </div>

                        </template>
                    </div>
                </div>
            </el-scrollbar>
        </el-container>
    </div>

</template>

<script>

    export default {
        name: "row",
        components:{
        },
        props: {
            base:{
                type: null,
                default: null
            },
        },
        data() {
            return {
                list:[],
            }
        },
        methods:{
            /**
             * 初始化数据
             */
            initData(e){
                let list = JSON.parse(JSON.stringify(e))
                for (let i = 0; i < list.length; i++) {
                    for (let j = 0; j < list[i].img.length; j++) {
                        if(list[i].img[j].indexOf('/') == -1){
                            list[i].img[j] = this.base + "/" + list[i].img[j]
                        }
                    }
                }
                console.log("base", this.base)
                console.log("list", list)

                this.list = list;
            },
        },
        mounted() {
        },
    }
</script>

<style scoped>
    .row{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: start;
    }
    .row_title{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        font-size: 22px;
        margin-bottom: 10px;
        white-space: pre-wrap;
        font-weight: 800;
    }
    .row_text{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        font-size: 22px;
        margin-bottom: 10px;
        white-space: pre-wrap;
    }
    .row_listImg{
        position: relative;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        flex-wrap:wrap;
    }
    .row_img{
        position: relative;
        max-width: 48%;
        max-height: 48%;
        margin-right: 5px;
        margin-bottom: 5px;
    }
    .row_tip{
        position: absolute;
        left: 10px;
        bottom: 10px;
        color: #ff1e0d;
        font-size: 16px;
        background: rgba(242, 242, 242, 0.3);
        border-radius: 4px;
        padding-left: 10px;
        padding-right: 10px;
    }

</style>

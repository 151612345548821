<template>
    <div>
        <row ref="row" :base="$options.name"></row>
    </div>
</template>

<script>
    import row from '@/components/row.vue'

    export default {
        name: "shouYe",
        components:{
            row
        },
        data() {
            return {
                list:[
                    {
                        title: "使用前检查",
                        text:[
                            '#初始环境必须成功(图1)',
                        ],
                        img:[
                            "com/init.png",
                        ]
                    },
                    {
                        title: "功能说明",
                        text:[
                            "#切换到授业页",
                            "#选择指定场景,角色也必须在这个场景,设置授业次数,到达次数后自动停止",
                        ],
                        img:[
                            "1.png",
                        ]
                    },
                    {
                        title: "游戏说明",
                        text:[
                            "#确保可以自动寻路到授业高人处,开始任务(图1)",
                            "#通过识别\"无法加入\",判断是否可以加入授业,如果不在授业时间,会等待10秒后重试,直到可以授业(图2)",
                            "#以上设置好,角色移动到指定场景,开始任务",
                        ],
                        img:[
                            "2.png",
                            "3.png",
                        ]
                    },
                ],
            }
        },
        methods:{

        },
        mounted() {
            this.$refs.row.initData(this.list);
        }
    }
</script>

<style scoped>

</style>
